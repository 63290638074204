import type { Content } from "#types/Config";

export type NavItemDefinition = {
  label: string;
  contentId: string | null;
  link: string;
  subnav?: NavItemDefinition[];
};

interface PageContent {
  [key: string]: {
    listOfContent?: Content[],
    series?: Content[],
    seriesTitle?: string,
  };
}

export const NAV_ITEMS: NavItemDefinition[] = [
  {
    contentId: 'nmvnEfMr',
    label: 'Baseball',
    link: '#',
  },
  {
    contentId: 'p8HnSoB0',
    label: 'Football',
    link: '#',
  },
  {
    contentId: 'WA3iVVPZ',
    label: 'Other Sports',
    link: '#',
  },
  {
    contentId: '8L0NoZKm',
    label: 'Warehouse Games',
    link: '#warehouse-games',
  },
  {
    contentId: 'EBUOOWkd',
    label: 'Lifestyle',
    link: '#lifestyle',
  },
  {
    contentId: 'kJ5OD8RK',
    label: 'Creators',
    link: '#creators',
  },
];

export const PAGE_CONTENT: PageContent = {
  nmvnEfMr: {
    seriesTitle: 'Baseball',
    series: [
      {
        contentId: '0KVTYx1w',
        title: 'Jomboy Breakdowns',
        type: 'playlist',
        thumbnail: '/images/thumbnails/Breakdowns.jpg',
        logo: '/images/logos/JMBreakdown.png'
      },
      {
        contentId: 'seSI0H2y',
        title: 'Talkin’ Baseball',
        type: 'playlist',
        thumbnail: '/images/thumbnails/TalkinBaseball.jpg',
        logo: '/images/logos/TB.png'
      },
      {
        contentId: 'heavXYBk',
        title: 'Talkin’ Yanks',
        type: 'playlist',
        thumbnail: '/images/thumbnails/TalkinYanks.jpg',
        logo: '/images/logos/TY.png'
      },
      {
        contentId: 'ZclsPy3R',
        title: 'Baseball Today',
        type: 'playlist',
        thumbnail: '/images/thumbnails/BaseballToday.jpg',
        logo: '/images/logos/BT.png'
      },
      {
        contentId: '',
        title: 'Wake n Jake',
        type: 'playlist',
        thumbnail: '/images/thumbnails/WakenJake.jpg',
        logo: '/images/logos/WJ.png'
      },
      {
        contentId: 'SlbomrZK',
        title: 'Chris Rose Rotation',
        type: 'playlist',
        thumbnail: '/images/thumbnails/ChrisRoseRotation.jpg',
        logo: '/images/logos/CRR.png'
      },
      {
        contentId: 'k3bivkvF',
        title: 'The Compounds',
        type: 'playlist',
        thumbnail: '/images/thumbnails/TheCompound.jpg',
        logo: '/images/logos/Compound.png'
      },
      {
        contentId: 'gUYsp5gs',
        title: 'Baseball Trivia',
        type: 'playlist',
        thumbnail: '/images/thumbnails/BaseballTrivia.jpg',
        logo: '/images/logos/JMBaseballTrivia.png'
      },
    ]
  },
  p8HnSoB0: {
    seriesTitle: 'Football',
    series: [
      {
        contentId: '9969pBkP',
        title: 'Football Today',
        type: 'playlist',
        thumbnail: '/images/football/FootballToday.jpg',
        logo: '/images/football/FootballToday2024.svg',
      },
      {
        contentId: 'TE5pJmR7',
        title: 'Football Trivia',
        type: 'playlist',
        thumbnail: '/images/football/NFLTrivia.jpg'
      },
      {
        contentId: 'od4AxMQ7',
        title: 'Talkin Giants - Full Episodes',
        type: 'playlist',
        thumbnail: '/images/football/noimage.jpg'
      },
      {
        contentId: 'RUa8IwGc',
        title: 'Power Rankings',
        type: 'playlist',
        thumbnail: '/images/football/PowerRankings.jpg',
      },
      {
        contentId: 'a1VCqW2X',
        title: 'JM Football All-Team',
        type: 'playlist',
        thumbnail: '/images/football/All-JMFootballTeam.jpg'
      },
      {
        contentId: 'jQajqtP5',
        title: 'Talkin Giants O-Line Report 2023',
        type: 'playlist',
        thumbnail: '/images/football/TalkinGiantsO-LineReport.jpg',
        logo: '/images/football/TalkinGiantslogo.jpg',
      },
      {
        contentId: '2lp7BKAr',
        title: 'Talkin Giants Film Review 2023',
        type: 'playlist',
        thumbnail: '/images/football/TalkinGiantsFilmReview.jpg',
        logo: '/images/football/TalkinGiantslogo.jpg',
      },
    ]
  },
  '8L0NoZKm': {
    seriesTitle: 'Warehouse Games',
    series: [
      {
        contentId: 'E1aPu66c',
        title: 'Blitzball Battle 4',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/BB4.jpg',
        logo: '/images/warehouse/logos/BlitzballBattle4.svg',
      },
      {
        contentId: 'KVtAcadi',
        title: 'Captain’s League - BIP',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/CaptainsLeague.jpg',
        logo: '/images/warehouse/logos/CaptainsLeague2024.svg',
      },
      {
        contentId: 'QtQ7ikeD',
        title: 'Floorball 2',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/Floorball2.jpg',
        logo: '/images/warehouse/logos/Floorball2.svg',
      },
      {
        contentId: 'W7RM4wns',
        title: 'Blitzball Blitz',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/Blitz.jpg',
        logo: '/images/warehouse/logos/BlitzballBlitz2023.svg',
      },
      {
        contentId: 'H2y8v2bp',
        title: 'Ball-in-Play League',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/BIP.jpg',
        logo: '/images/warehouse/logos/BallinPlayLeague.svg',
      },
      {
        contentId: 'E87LYLDC',
        title: 'Blitzball Battle 3',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/BB3.jpg',
        logo: '/images/warehouse/logos/BlitzballBattle3.svg',
      },
      {
        contentId: 'ba82o6LS',
        title: '1-Inning League Roulette',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/1ILR.jpg',
        logo: '/images/warehouse/logos/1-InningLeagueRoulette.svg',
      },
      {
        contentId: 'DSSWmRI0',
        title: 'Blitzball Battle 2',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/BB2.jpg',
        logo: '/images/warehouse/logos/BlitzballBattle2.svg',
      },
      {
        contentId: 'yclWMJGk',
        title: 'Ball-in-Play Tester League',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/noimage.jpg',
        logo: '/images/warehouse/logos/BallinPlayLeague.svg',
      },
      {
        contentId: '',
        title: 'Mini Golf',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/MiniGolf.jpg',
        logo: '/images/warehouse/logos/Minigolf2022.svg',
      },
      {
        contentId: 'vwChpFTX',
        title: 'Floorball 1',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/Floorball.jpg',
        logo: '/images/warehouse/logos/Floorball1.svg',
      },
      {
        contentId: 'tuPC8y4p',
        title: 'The 1-Inning League',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/1IL.jpg',
        logo: '/images/warehouse/logos/1-InningLeague.svg',
      },
      {
        contentId: 'mL9VGX7W',
        title: 'Blitzball Battle 1',
        type: 'playlist',
        thumbnail: '/images/warehouse/thumbnails/BB1.jpg',
        logo: '/images/warehouse/logos/BlitzballBattle1.svg',
      },
    ]
  },
  EBUOOWkd: {
    seriesTitle: 'Lifestyle',
    series: [
      {
        contentId: '7SJxPPse',
        title: 'Weekly Dumb',
        type: 'playlist',
        thumbnail: '/images/lifestyle/WeeklyDumb.jpg',
      },
      {
        contentId: 'Tb8xIkrI',
        title: 'Watchin’ GUTS',
        type: 'playlist',
        thumbnail: '/images/lifestyle/WatchinGuts.jpg',
      },
      {
        contentId: '7zcmptA1',
        title: 'Watchin’ Baggage',
        type: 'playlist',
        thumbnail: '/images/lifestyle/WatchinBaggage.jpg',
      },
      {
        contentId: 'FCJAcepm',
        title: 'The Morning Meeting',
        type: 'playlist',
        thumbnail: '/images/lifestyle/MorningMeeting.jpg',
      },
      {
        contentId: '4gpr1d0g',
        title: 'Office Vlogs',
        type: 'playlist',
        thumbnail: '/images/lifestyle/OfficeVlog.jpg',
      },
      {
        contentId: 'PGuyK3RB',
        title: 'Those GeoGodGuys',
        type: 'playlist',
        thumbnail: '/images/lifestyle/GeoGuessr.jpg',
      },
      {
        contentId: 'X6CJVwqe',
        title: 'Office Competitions',
        type: 'playlist',
        thumbnail: '/images/lifestyle/OfficeTournament.jpg',
      },
      {
        contentId: 'mBVfGxeS',
        title: 'Ten Minute Tournaments',
        type: 'playlist',
        thumbnail: '/images/lifestyle/10MinuteTourneys.jpg',
      },
      {
        contentId: 'AxKYuPPe',
        title: 'Rankings',
        type: 'playlist',
        thumbnail: '/images/lifestyle/Rankings.jpg',
      },
      {
        contentId: 'QFmm1cAj',
        title: 'Blind Taste Tests',
        type: 'playlist',
        thumbnail: '/images/lifestyle/BlindTasteTest.jpg',
      },
      {
        contentId: 'rYACn8uG',
        title: 'Fall at the Farm',
        type: 'playlist',
        thumbnail: '/images/lifestyle/FallattheFarm.jpg',
      },
      {
        contentId: 'dfy69ZUW',
        title: 'Laughs from the Past',
        type: 'playlist',
        thumbnail: '/images/lifestyle/LaughsFromthePast.jpg',
      },
    ]
  },
  kJ5OD8RK: {
    seriesTitle: 'Creators',
    hideVideos: true,
    series: [
      {
        contentId: 'i3EElSru',
        title: 'Jomboy',
        type: 'playlist',
        thumbnail: '/images/creators/Jomboy.jpg',
      },
      {
        contentId: '4VW5eVaH',
        title: 'Talkin’ Jake',
        type: 'playlist',
        thumbnail: '/images/creators/TalkinJake.jpg',
      },
      {
        contentId: 'VMyA7fbu',
        title: 'Joez McFly',
        type: 'playlist',
        thumbnail: '/images/creators/Joez.jpg',
      },
      {
        contentId: 'ge0lNzgr',
        title: 'Jolly Olive',
        type: 'playlist',
        thumbnail: '/images/creators/JollyOlive.jpg',
      },
      {
        contentId: '1uk07kSr',
        title: 'Justin Penik',
        type: 'playlist',
        thumbnail: '/images/creators/JustinPenik.jpg',
      },
      {
        contentId: 'bQtnD6BF',
        title: 'Bobby Skinner',
        type: 'playlist',
        thumbnail: '/images/creators/BobbySkinner.jpg',
      },
      {
        contentId: 'IzlKMN5a',
        title: 'Zo DeMalia',
        type: 'playlist',
        thumbnail: '/images/creators/noimage.jpg',
      },
      {
        contentId: 'KJMmKkzW',
        title: 'Jack Doyle',
        type: 'playlist',
        thumbnail: '/images/creators/JackDoyle.jpg',
      },
      {
        contentId: '',
        title: 'Shelfy',
        type: 'playlist',
        thumbnail: '/images/creators/Shelfy.jpg',
      },
      {
        contentId: 'l44RjUyH',
        title: 'Trevor Plouffe',
        type: 'playlist',
        thumbnail: '/images/creators/TrevorPlouffe.jpg',
      },
      {
        contentId: 'VPAw17Ag',
        title: 'Chris Rose',
        type: 'playlist',
        thumbnail: '/images/creators/ChrisRose.jpg',
      },
      {
        contentId: '3Oy02pXe',
        title: 'Kelsey Wingert',
        type: 'playlist',
        thumbnail: '/images/creators/KelseyWingert.jpg',
      },
      {
        contentId: 'UhAmk8o0',
        title: 'Peter Moylan',
        type: 'playlist',
        thumbnail: '/images/creators/PeterMoylan.jpg',
      },
      {
        contentId: 'BpL5TNWf',
        title: 'Ian Happ',
        type: 'playlist',
        thumbnail: '/images/creators/IanHapp.jpg',
      },
    ]
  },
};

export const PASSWORD = 'jakesucks1427';




