import React, { useState } from 'react';

import styles from './Newsletter.module.scss';

import Button from '#components/Button/Button';
import TextField from '#components/TextField/TextField';

const NewsletterSignup: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // URL where the email data needs to be sent
    const url = 'https://watch.jomboymedia.com/newsletter-signup';

    try {
      const formData = new FormData();
      formData.append('email', email);

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to submit email');
      }
    } catch (error: unknown) {
      // @ts-ignore
      setMessage('Error: ' + error.message);
    }
  };

  return (
    <div className={styles.newsletter}>
      <div className={styles.formContainer}>
        <div>
          <h4>Subscribe to the Newsletter</h4>
          <span className={styles.description}>We’ll send you 10% off your Shop purchase.</span>
            <form onSubmit={handleEmailSubmit}>
              <TextField
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={'Email'}
                type="email"
                required
              />
              <Button className={styles.button} color="primary" variant="contained" size="large" label={'Sign Up'} type={'submit'} />
              <div>{message}</div>
            </form>
        </div>
      </div>
      <div className={styles.newsletterRight}></div>
    </div>
  );
};

export default NewsletterSignup;
