import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import classNames from "classnames";

import styles from './PasswordProtectOverlay.module.scss';

import Button from '#components/Button/Button';
import TextField from "#components/TextField/TextField";
import { PASSWORD } from "#src/content/config";

const PasswordProtectOverlay: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(false);

  useEffect(() => {
    const cookiePassword = Cookies.get('sitePassword');
    if (cookiePassword === PASSWORD) {
      setIsAuthenticated(true);
      setVisible(false);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (!isAuthenticated && rootElement) {
      rootElement.style.overflowY = 'hidden';
    } else if (rootElement) {
      rootElement.style.overflowY = 'visible';
    }

    return () => {
      if (rootElement) {
        rootElement.style.overflowY = 'visible';
      }
    };
  }, [isAuthenticated]);

  const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // URL where the email data needs to be sent
    const url = 'https://watch.jomboymedia.com/newsletter-signup';

    try {
      const formData = new FormData();
      formData.append('email', email);

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to submit email');
      } else {
        setShowPasswordInput(true);  // Switch to password input
      }
    } catch (error: unknown) {
      // @ts-ignore
      setMessage('Error: ' + error.message);
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handlePasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password === PASSWORD) {
      Cookies.set('sitePassword', password, {expires: 90}); // Expires in 90 days
      setIsAuthenticated(true);
      setTimeout(() => setVisible(false), 300);
    } else {
      setMessage('Incorrect password, please try again.');
    }
  };

  if (loading || !visible) {
    return null;
  }

  const modalStyle = {
    opacity: isAuthenticated ? 0 : 1,
  };

  return (
    <div className={styles.modal} style={modalStyle}>
      <div className={styles.newsletter}>
        <div className={styles.formContainer}>
          <div>
            <h4>Get Early Access</h4>
            <span className={styles.description}>Enter your details below to proceed.</span>
            {!isAuthenticated && (
              showPasswordInput ? (
                <form onSubmit={handlePasswordSubmit}>
                  <TextField
                    className={styles.input}
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder={'Password'}
                    name="password"
                    type="password"
                    required
                  />
                  <Button className={styles.button} color="primary" variant="contained" size="large" label={'Unlock'} type={'submit'} />
                  <div className={styles.erroMessage}>{message}</div>
                </form>
              ) : (
                <form onSubmit={handleEmailSubmit}>
                  <TextField
                    className={styles.input}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={'Email'}
                    name="email"
                    type="email"
                    required
                  />
                  <Button className={styles.button} color="primary" variant="contained" size="large" label={'Continue'} type={'submit'} />
                  <Button className={classNames(styles.button, styles.secondaryButton)} variant="contained" size="small" label={'I already have a password'} onClick={() => setShowPasswordInput(true)} />
                  <div className={styles.erroMessage}>{message}</div>
                </form>
              )
            )}
          </div>
        </div>
        <div className={styles.newsletterRight}></div>
      </div>
    </div>
  );
};

export default PasswordProtectOverlay;
