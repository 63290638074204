import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';
import shallow from 'zustand/shallow';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import styles from './Layout.module.scss';

import { useAccountStore } from '#src/stores/AccountStore';
import { useUIStore } from '#src/stores/UIStore';
import { useConfigStore } from '#src/stores/ConfigStore';
import useSearchQueryUpdater from '#src/hooks/useSearchQueryUpdater';
import Button from '#components/Button/Button';
import MarkdownComponent from '#components/MarkdownComponent/MarkdownComponent';
import Header, {TypeHeader} from '#components/Header/Header';
import Sidebar from '#components/Sidebar/Sidebar';
import MenuButton from '#components/MenuButton/MenuButton';
import UserMenu from '#components/UserMenu/UserMenu';
import { addQueryParam } from '#src/utils/location';
import { getSupportedLanguages } from '#src/i18n/config';
import { ACCESS_MODEL } from '#src/config';
import { NavItemDefinition, NAV_ITEMS } from '#src/content/config';
import { useProfileStore } from '#src/stores/ProfileStore';
import { useProfiles, useSelectProfile } from '#src/hooks/useProfiles';
import { useGoogleAnalytics } from '#src/hooks/useGoogleAnalytics';
import { IS_DEVELOPMENT_BUILD } from '#src/utils/common';
import ProfileController from '#src/stores/ProfileController';
import { getModule } from '#src/modules/container';
import PasswordProtectOverlay from "#components/PasswordProtectOverlay/PasswordProtectOverlay";
import {slugify} from "#src/utils/formatting";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('common');

  const { config, accessModel, clientId } = useConfigStore(({ config, accessModel, clientId }) => ({ config, accessModel, clientId }), shallow);
  const isLoggedIn = !!useAccountStore(({ user }) => user);
  const favoritesEnabled = !!config.features?.favoritesList;
  //const { menu, assets, siteName, description, styling, features } = config;
  const { assets, siteName, description, features } = config;
  const metaDescription = description || t('default_description');

  const [scrollParam, setScrollParam] = useState<TypeHeader>('static');

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      if (position > 100) {
        setScrollParam('fixed');
      } else {
        setScrollParam('static');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);useEffect(() => {
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 100) {
      setScrollParam('fixed');
    } else {
      setScrollParam('static');
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

  const profileController = getModule(ProfileController, false);

  type SubmenuVisibility = {
    [key: string]: boolean;
  };
  const [submenuVisibility, setSubmenuVisibility] = useState<SubmenuVisibility>({});

  const toggleSubmenu = (index: string | number) => {
    setSubmenuVisibility((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const { searchPlaylist } = features || {};
  //const { footerText } = styling || { footerText: 'All Rights Reserved, Jomboy Media Corp.' };
  const footerText = 'All Rights Reserved, Jomboy Media Corp.';
  const supportedLanguages = useMemo(() => getSupportedLanguages(), []);
  const currentLanguage = useMemo(() => supportedLanguages.find(({ code }) => code === i18n.language), [i18n.language, supportedLanguages]);

  const {
    query: { data: { responseData: { collection: profiles = [] } = {} } = {} },
    profilesEnabled,
  } = useProfiles();

  const selectProfile = useSelectProfile();

  const { searchQuery, searchActive, userMenuOpen, languageMenuOpen } = useUIStore(
    ({ searchQuery, searchActive, userMenuOpen, languageMenuOpen }) => ({
      languageMenuOpen,
      searchQuery,
      searchActive,
      userMenuOpen,
    }),
    shallow,
  );
  const { updateSearchQuery, resetSearchQuery } = useSearchQueryUpdater();
  const { profile } = useProfileStore();

  const searchInputRef = useRef<HTMLInputElement>(null) as React.MutableRefObject<HTMLInputElement>;

  const [sideBarOpen, setSideBarOpen] = useState(false);
  const banner = assets.banner;

  useGoogleAnalytics('G-NMH3WECX70');

  useEffect(() => {
    if (isLoggedIn && profilesEnabled && !profiles?.length) {
      profileController?.unpersistProfile();
    }
    // Trigger once on the initial page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchActive && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchActive]);

  const searchButtonClickHandler = () => {
    useUIStore.setState({
      searchActive: true,
      preSearchPage: location,
    });
  };

  const closeSearchButtonClickHandler = () => {
    resetSearchQuery();

    useUIStore.setState({
      searchActive: false,
    });
  };

  const loginButtonClickHandler = () => {
    navigate(addQueryParam(location, 'u', 'login'));
  };

  const signUpButtonClickHandler = () => {
    navigate(addQueryParam(location, 'u', 'create-account'));
  };

  const languageClickHandler = async (code: string) => {
    await i18n.changeLanguage(code);
  };

  // useCallbacks are used here to fix a bug in the Popover when using a Reactive onClose callback
  const openUserMenu = useCallback(() => useUIStore.setState({ userMenuOpen: true }), []);
  const closeUserMenu = useCallback(() => useUIStore.setState({ userMenuOpen: false }), []);
  const openLanguageMenu = useCallback(() => useUIStore.setState({ languageMenuOpen: true }), []);
  const closeLanguageMenu = useCallback(() => useUIStore.setState({ languageMenuOpen: false }), []);

  const renderUserActions = () => {
    if (!clientId) return null;

    return isLoggedIn ? (
      <UserMenu showPaymentsItem={accessModel !== ACCESS_MODEL.AVOD} favoritesEnabled={favoritesEnabled} />
    ) : (
      <div className={styles.buttonContainer}>
        <Button fullWidth onClick={loginButtonClickHandler} label={t('sign_in')} />
        <Button variant="contained" color="primary" onClick={signUpButtonClickHandler} label={t('sign_up')} fullWidth />
      </div>
    );
  };

  return (
    <div className={styles.layout}>
      <Helmet>
        <title>{siteName}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:title" content={siteName} />
        <meta name="twitter:title" content={siteName} />
        <meta name="twitter:description" content={metaDescription} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Inter" media="all" />
        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Syne:wght@800&display=swap" rel="stylesheet" />

      </Helmet>
      <div className={styles.main}>
        <PasswordProtectOverlay />
        <Header
          headerType={scrollParam}
          onMenuButtonClick={() => setSideBarOpen(true)}
          logoSrc={banner}
          searchEnabled={!!searchPlaylist}
          searchBarProps={{
            query: searchQuery,
            onQueryChange: (event) => updateSearchQuery(event.target.value),
            onClearButtonClick: () => updateSearchQuery(''),
            inputRef: searchInputRef,
          }}
          searchActive={searchActive}
          onSearchButtonClick={searchButtonClickHandler}
          onCloseSearchButtonClick={closeSearchButtonClickHandler}
          onLoginButtonClick={loginButtonClickHandler}
          onSignUpButtonClick={signUpButtonClickHandler}
          onLanguageClick={languageClickHandler}
          supportedLanguages={supportedLanguages}
          currentLanguage={currentLanguage}
          isLoggedIn={isLoggedIn}
          userMenuOpen={userMenuOpen}
          languageMenuOpen={languageMenuOpen}
          openUserMenu={openUserMenu}
          closeUserMenu={closeUserMenu}
          openLanguageMenu={openLanguageMenu}
          closeLanguageMenu={closeLanguageMenu}
          canLogin={!!clientId}
          showPaymentsMenuItem={accessModel !== ACCESS_MODEL.AVOD}
          favoritesEnabled={favoritesEnabled}
          profilesData={{
            currentProfile: profile,
            profiles,
            profilesEnabled,
            selectProfile: ({ avatarUrl, id }) => selectProfile.mutate({ id, avatarUrl }),
            isSelectingProfile: !!selectProfile.isLoading,
          }}
        >
          <></>
          <ul className={styles.nav}>
            {NAV_ITEMS.map((item: { contentId: React.Key | null | undefined; label: string, link?: string, subnav?: NavItemDefinition[] }, index) => (
              <li key={index} className={styles.menuItem}>
                <Button label={item.label} to={item.contentId ? `/p/${item.contentId}/${slugify(item.label)}` : item.link} variant="text">
                  {item.subnav && <FontAwesomeIcon icon={faCaretDown} />}
                </Button>

                {item.subnav && (
                  <ul className={styles.submenu}>
                    {item.subnav.map((subItem: NavItemDefinition, index: React.Key | null | undefined) => (
                      <li key={index}>
                        <Button to={subItem.contentId ? `/p/${subItem.contentId}/${slugify(subItem.label)}` : subItem.link} label={subItem.label} className={styles.submenuItem} variant="text" />
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </Header>
        <Sidebar isOpen={sideBarOpen} onClose={() => setSideBarOpen(false)} className={styles.mobileSidebar}>
          {NAV_ITEMS.map((item: { contentId: React.Key | null | undefined; label: string | undefined, link?: string, subnav?: NavItemDefinition[] }, index) => (
            <>
              <MenuButton
                className=''
                key={index}
                label={item.label}
                to={item.contentId ? `/p/${item.contentId}/${slugify(item.label)}` : item.link}
                tabIndex={sideBarOpen ? 0 : -1}
                onClick={() => item.subnav ? toggleSubmenu(index) : setSideBarOpen(false)}
              >
                {item.subnav && <>&nbsp;<FontAwesomeIcon icon={faCaretDown} /></>}
              </MenuButton>

            {item.subnav && submenuVisibility[index] && (
              <ul className={styles.submenu}>
                {item.subnav.map((subItem: NavItemDefinition, index: React.Key | null | undefined) => (
                  <li key={index}>
                    <MenuButton
                      onClick={() => setSideBarOpen(false)}
                      to={subItem.contentId ? `/p/${subItem.contentId}/${slugify(subItem.label)}` : subItem.link}
                      label={subItem.label}
                      className={styles.submenuItem}
                      variant="text"
                    />
                  </li>
                ))}
              </ul>
            )}
            </>
          ))}

          {renderUserActions()}
        </Sidebar>
        <Outlet />
      </div>
      {!!footerText && (
        <div className={styles.footer}>

          <div>
            <div className={styles.footerNav}>
              <a href={"https://shop.jomboymedia.com/"}>SHOP</a>
              <a href={"https://jomboymedia.com/"}>PARTNERSHIP</a>
              <a href={"https://jomboymedia.com/contact-us/"}>CONTACT US</a>
            </div>

            <a className={styles.footer_icon} href="https://facebook.com/jomboymedia" target="_blank" rel="noreferrer">
              <span className={styles.screen_only}>Facebook</span>
              <FontAwesomeIcon icon={faFacebook} />
            </a>

            <a className={styles.footer_icon} href="https://twitter.com/jomboymedia" target="_blank" rel="noreferrer">
              <span className={styles.screen_only}>Twitter</span>
              <FontAwesomeIcon icon={faTwitter} />
            </a>

            <a className={styles.footer_icon} href="https://www.instagram.com/jomboymedia" target="_blank" rel="noreferrer">
              <span className={styles.screen_only}>Instagram</span>
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>

          <MarkdownComponent
            // The extra style below is just to fix the footer on mobile when the dev selector is shown
            className={classNames({ [styles.testFixMargin]: IS_DEVELOPMENT_BUILD })}
            markdownString={footerText}
            inline
          />
        </div>
      )}
    </div>
  );
};

export default Layout;
