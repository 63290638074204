import React, {useEffect} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import styles from './ScreenRouting.module.scss';

import Button from '#components/Button/Button';
import Loading from '#src/pages/Loading/Loading';
import ErrorPage from '#components/ErrorPage/ErrorPage';
import usePlaylist from '#src/hooks/usePlaylist';
import type { Playlist } from '#types/playlist';
import PlaylistGrid from '#src/pages/ScreenRouting/playlistScreens/PlaylistGrid/PlaylistGrid';
import PlaylistLiveChannels from '#src/pages/ScreenRouting/playlistScreens/PlaylistLiveChannels/PlaylistLiveChannels';
import { ScreenMap } from '#src/pages/ScreenRouting/ScreenMap';
import { CONTENT_TYPE } from '#src/config';
import ShelfList from "#src/containers/ShelfList/ShelfList";
import { PAGE_CONTENT } from '#src/content/config';
import Image from "#components/Image/Image";
import InlinePlayer from "#src/containers/InlinePlayer/InlinePlayer";
import {slugify} from "#src/utils/formatting";

export const playlistScreenMap = new ScreenMap<Playlist>();

// register playlist screens
playlistScreenMap.registerByContentType(PlaylistLiveChannels, CONTENT_TYPE.live);
playlistScreenMap.registerDefault(PlaylistGrid);

const PlaylistScreenRouter = () => {
  const params = useParams();
  const id = params.id || '';
  const { isLoading, isFetching, error, data } = usePlaylist(id);
  const { t } = useTranslation('error');
  const { listOfContent } = PAGE_CONTENT[id] || {};
  const { series = [], seriesTitle } = PAGE_CONTENT[id] || {};
  let { hideVideos } = PAGE_CONTENT[id] || {};
  const isSeries = data?.isSeries;
  const JM_Playlists = data?.JM_Playlists;

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ErrorPage title={t('playlist_not_found')} />;
  }

  const PlaylistScreen = playlistScreenMap.getScreen(data);

  let firstVideo;
  if (isSeries) {
    firstVideo = data.playlist[0];
  }

  let content;
  if (JM_Playlists) {
    hideVideos = true;
    content = JM_Playlists?.split(',')?.map((playlistId) => ({
      contentId: playlistId,
      enableText: false,
      featured: false,
      type: "playlist",
    }))
  }

  return (<>
      {
        !!series.length && <div className={styles.seriesWrapper}>
          <header className={styles.header}>
            <h1 className={styles.title}>{seriesTitle}</h1>
          </header>
          <div className={styles.seriesContainer}>
            {
              series.map((series: { title: any; contentId: any; thumbnail: string | undefined; logo: string | undefined; }, index: React.Key | null | undefined) => {
                return (
                  <Button key={index} label={series.title || ''} to={`/p/${series.contentId}/${slugify(series.title)}`} variant="text" className={styles.series}>
                    <div className={styles.imageWrapper}>
                      {series.thumbnail && <Image className={styles.thumbnail} image={series.thumbnail} width={500} />}
                      {series.logo && <Image className={styles.logo} image={series.logo} width={100} />}
                    </div>
                  </Button>
                );
              })
            }
          </div>
        </div>
      }
      {listOfContent && <ShelfList rows={listOfContent} />}

    {firstVideo &&
      <div className={styles.playerWrapper}>
        <InlinePlayer
          isLoggedIn={false}
          item={firstVideo}
          onComplete={() => {}}
          feedId={data.feedId ?? undefined}
          startWatchingButton={<></>}
          paywall={false}
          autostart={true}
        />
      </div>
      }

      {content && <ShelfList rows={content} />}

      {!hideVideos && <PlaylistScreen data={data} isLoading={isFetching} />}
    </>);
};

export default PlaylistScreenRouter;
