import { useEffect } from 'react';

export const useGoogleAnalytics = (trackingId: string): void => {
  useEffect(() => {
    // Check if the script is already loaded
    if (window.gtag) return;

    // Create a script element and append it to 'head'
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize dataLayer and gtag function
    window.dataLayer = window.dataLayer || [];
    const gtag: Gtag.Gtag = (...args) => {
      window.dataLayer.push(args);
    };
    window.gtag = gtag;

    // Setup initial configuration
    gtag('js', new Date());
    gtag('config', trackingId, { send_page_view: false });
  }, [trackingId]);  // Only re-run the effect if trackingId changes
};

